<template>
  <el-main>
    <el-form :model="form" status-icon ref="form" label-width="130px" :rules="rules">
      <el-tabs v-model="activeName">
        <el-tab-pane label="项目介绍" name="project_introduce">
          <el-form-item label="项目介绍：" prop="project_introduce" v-if="activeName == 'project_introduce'">
            <Rich class="rich" @getContent="getContent" :richTxt="form.project_introduce" editorId="project_introduce"></Rich>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="我们的使命" name="our_mission">
          <el-form-item label="我们的使命：" prop="our_mission" v-if="activeName == 'our_mission'">
            <Rich class="rich" @getContent="getContent" :richTxt="form.our_mission" editorId="our_mission"></Rich>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import Rich from '@/components/richText';
export default {
  components: {
    Preservation,
    Rich,
  },
  data() {
    return {
      activeName: 'project_introduce',
      form: {
        project_introduce: '',
        our_mission: '',
      },
      rules: {
        project_introduce: [{ required: true, message: '请填写项目介绍', trigger: 'blur' }],
        our_mission: [{ required: true, message: '请填写我们的使命', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getTextInfo();
  },
  methods: {
    Preservation() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.PCOfficialWebsite.editTextInfo, this.form).then(res => {
            if (res.code == 0) {
              this.$message.success('保存成功');
              this.getTextInfo();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getContent(val, onName) {
      this.form[onName] = val;
    },
    getTextInfo() {
      this.$axios.post(this.$api.PCOfficialWebsite.getTextInfo).then(res => {
        if (res.code == 0) {
          if (res.result) this.form = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 80px;
}
</style>
